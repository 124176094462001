import { getIPInfo } from '../api/api'
import moment from 'moment'

/* Check if Table and desktop */
export const desktopDesign = () => {
  if (
    window.deviceType &&
    (window.deviceType.isTablet || !window.deviceType.isMobile)
  ) {
    return true
  }
  return false
}

/* Check if only Desktop */
export const onlyDesktopDesign = () => {
  if (
    window.deviceType &&
    !window.deviceType.isTablet &&
    !window.deviceType.isMobile
  ) {
    return true
  }
  return false
}

/* Check if only mobile */
export const mobileDesign = () => {
  if (
    window.deviceType &&
    window.deviceType.isMobile &&
    !window.deviceType.isTablet
  ) {
    return true
  }
  return false
}

/* Extract UTMs from URL to their own properties */

export const extractUTM = () => {
  if (window.location.search && window.location.search.length > 0) {
    const utmArray = window.location.search
      .substring(window.location.search.indexOf('?') + 1)
      .split('&')

    const utms = {
      utm_campaign: 'bolivar',
    }

    utmArray.forEach((utm) => {
      const [key, value] = utm.split('=')
      if (key === 'utm_campaign') {
        utms.utm_campaign = value
      }
      if (key === 'utm_source') {
        utms.utm_source = value
      }
      if (key === 'utm_medium') {
        utms.utm_medium = value
      }
      if (key === 'utm_term') {
        utms.utm_term = value
      }
    })

    return utms
  }
}

/**
 * This function return a new query string from the current URL or a custom url
 * passed as parameter with the keys passed in {paramsToGet}
 * @param {Array} paramsToGet the shape of the array must be as follows
 *  [{
 *    @param {String} key: '', key param you want to get
 *    @param {Boolean} replaceValue: false,
 *    @param {String} valueToReplace: '', String value you that want to replace with
 *  }]
 * @param {String} URLString work with a different URL, not with current
 */
export const getURLStringParams = (paramsToGet, URLString = window.location.search) => URLString
  .substring(1)
  .split('&')
  .map((param) => {
    const [key, value] = param.split('=')
    const keyToFilter = paramsToGet.find((paramToFilter) => paramToFilter.key === key)
    if (keyToFilter) {
      return {
        [key]: keyToFilter.replaceValue ? keyToFilter.valueToReplace : value
      }
    }
    return false
  })
  .filter((value) => !!value)
  .map((obj) => Object.entries(obj)
    .map(([key, value]) => `${key}=${value}`)[0])
  .join('&')

export const isValidIPV4Address = (IPAddress) => /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(IPAddress)

export const isValidIPV6Address = (IPAddress) => /^((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*::((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*|((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4})){7}$/.test(IPAddress)

export const isIPFromIndeed = async () => {
  const { ip } = await getIPInfo(window.activeEnvironment || 'staging')
  const IPsToFilter = window.IpsFromIndeed || ''
  const IPs = IPsToFilter
    .split(',')
    .filter((_) => isValidIPV4Address(_) || isValidIPV6Address(_))
  return {
    result: IPs.includes(ip),
    evaluatedIp: ip,
  }
}

export const fixUploadedFileDate = (dateString) => {
  let formatDate = moment(dateString);
  if (!formatDate.isValid()) {
    formatDate = moment(new Date());
  }
  return formatDate.format("YYYY-MM-DDTHH:mm:ss.SSSSZ");
};

const es = {
  jobDescription: {
    descriptionTitle: 'DESCRIPCIÓN',
    applyButtonText: 'APLICAR',
    continueButtonText: 'CONTINUAR',
    alreadyApplied: 'YA HAS APLICADO',
    termsAndConditionsText:
      'Al marcar esta casilla, acepta nuestros <a href="https://www.apploi.com/terms-conditions/" target="_blank">Términos y Condiciones</a>, <a href="https://www.apploi.com/cookie-policy/" target="_blank">Cookies</a> y <a href="https://www.apploi.com/privacy_policy/" target="_blank">Políticas de privacidad</a>. También brinda su consentimiento para recibir mensajes SMS (incluidos mensajes de texto y MMS) de [Company Name] con respecto a su solicitud de empleo y/u otras oportunidades. Es posible que se apliquen tarifas de mensajería y datos. La frecuencia de los mensajes puede variar. Para cancelar la recepción de mensajes SMS, puede responder y enviar un mensaje de texto con la palabra "STOP".',
    location: 'UBICACIÓN',
    about: 'ACERCA DE',
    industry: 'Industria',
    companyWebsite: 'Sitio web de la compañia',
    photos: 'FOTOS',
  },
  jobDescriptionFullApply: {
    school: 'Institución Educativa',
    major: 'Especialización (Opcional)',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha finalización',
    educationLevel: 'Nivel Educativo',
    educationGraduated: 'Me gradué de esta institución',
    educationAddMore: '+ Agregar más detalles educativos',
    educationAddDetailsTitle: 'Agrege detalles sobre su formación educativa:',
    educationAddDetailsSubTitle:
      '(Se autocompleta si se adjunta la hoja de vida)',
    employer: 'Empresa',
    jobTitle: 'Cargo',
    jobDescription: 'Descripción del cargo',
    presentJob: 'Este es mi trabajo actual',
    addMoreExperience: '+ Agregar otra experiencia laboral',
    jobAddDetailsTitle: 'Agregue detalles sobre su experiencia laboral:',
    jobAddDetailsSubTitle: '(Se autocompleta si se adjunta la hoja de vida)',
    firstName: 'Nombres',
    lastName: 'Apellidos',
    address: 'Dirección',
    city: 'Ciudad',
    state: 'Estado',
    postalCode: 'Código Postal',
    country: 'País',
    email: 'Correo electrónico',
    phone: 'Teléfono',
    expand: 'EXPANDIR',
    collapse: 'CONTRAER',
    expandAll: 'EXPANDIR TODO',
    collapseAll: 'CONTRAER TODO',
    allChangesSaved: 'Todos los cambios han sido guardados.',
    saving: 'Guardando… Por favor espere un momento.',
    interviewQuestions: 'Preguntas de entrevista',
    applyNow: 'Aplicar ahora',
    submit: 'ENVIAR',
    backToTop: 'Volver al inicio',
    uploadResume: 'ADJUNTAR HOJA DE VIDA',
    uploadResumeOptional: '(opcional)',
    uploadResumeWarning:
      'Cargar la Hoja de Vida es requerido para aplicar a este trabajo!',
    uploadCompleteTitle: 'CARGA COMPLETADA!',
    uploadCompleteText:
      'Por favor revise la información cargada en el formulario. La carga de la hoja de vida no siempre es perfecta.',
    uploadError1:
      'Ocurrio un error procesando su Hoja de Vida! Por favor intente nuevamente.',
    uploadError2:
      'Por favor adjunte un archivo en alguno de los formatos soportados. Formatos soportados son .pdf, .docx, and .doc.',
    signatureIndication: 'Use el ratón del computador para firmar',
    signatureClear: 'LIMPIAR',
    Monday: 'Lunes',
    Tuesday: 'Martes',
    Wednesday: 'Miércoles',
    Thursday: 'Jueves',
    Friday: 'Viernes',
    Saturday: 'Sábado',
    Sunday: 'Domingo',
    weekdays: 'Días de la semana',
    weeknights: 'Noches de la semana',
    weekends: 'Fin de semana',
    anytime: 'Cualquier horario',
    allDay: 'Todo el día',
    to: 'Hasta',
    optional: 'Opcional',
    selectDate: 'Seleccione una fecha',
    videoMinute: 'minuto',
    videoMinutes: 'minutos',
    videoNoLimits: 'Sin limite',
    videoNew: 'Cargar nuevo video',
    videoLimit: 'Límite de tiempo',
    videoText1:
      'Para fines de identificación con foto, haz clic en grabar y enviar instantáneamente.',
    videoText2: 'Recomendaciones para entrevista por video',
    videoText3:
      'Al contrario de una entrevista normal, puedes re-grabar tus respuestas las veces que quieras.',
    videoText4: 'Sonríe y mira directamenta a la cámara.',
    videoText5:
      'Presta atención a tu lenguaje: no digas palabrotas y no uses jerga.',
    videoText6: 'Busca un lugar con buena luz y sin distracciones para grabar.',
    videoText7: '¡Vístete apropiadamente!',
    sliderLikely: 'PROBABLE',
    sliderVery: 'MUY',
    sliderNot: 'POCO',
    sliderClear: 'Limpiar',
    incorrectTimeAvailability:
      'Ha seleccionado un valor incorrecto para la disponibilidad de tiempo. Por favor intente nuevamente.',
    autoSaveFailed: 'Autoguardado Falló',
    personalQuestionsAutoSaveFailed:
      'Autoguardado de preguntas personales falló',
    remove: 'Eliminar',
    submitConfirmation: '¿ ESTÁ SEGURO QUE DESEA ENVIAR LA INFORMACIÓN ?',
    submitOk: 'ENVIAR',
    submitCancel: 'CANCELAR',
    submitSuccessful: 'La información se ha enviado correctamente.',
    submitValidationError:
      '¡Algo salió mal. Por favor recargue la pagina y vuelva a intentar!',
    submitValidationIncomplete:
      '¡Por favor complete todas las secciones. Las secciones incompletas están marcadas con rojo!',
    submitValidationPhoneError: 'Por favor ingrese un numero de telefono valido.',
  },
  jobSalary: {
    compensation: 'Salario',
    upTo: 'Hasta',
    perHour: 'por hora',
    perDiem: 'por días',
    perMonth: 'mensual',
    perYear: 'anual',
  },
  jobExperience: {
    experienceTitle: 'Mínimo de Años de Experiencia',
    year: 'año',
    years: 'años',
    none: 'Ninguno',
  },
  jobLevelOfEducation: {
    educationTitle: 'Nivel de Educación Mínimo',
    certificate: 'Certificado',
    highSchool: 'Preparatoria',
    associate: 'Título de Asociado',
    bachelors: 'Título de grado',
    master: 'Maestría',
    other: 'Otro',
    none: 'Ninguno',
  },
  account: {
    myAccount: 'MI CUENTA',
    signOut: 'CERRAR SESIÓN',
  },
  internal: {
    searchTitle: 'BUSCAR PUESTOS VACANTES',
    searchInput: 'Escribe palabra clave',
    searchLocation: 'Ingresa una locación...',
    searchButton: 'BUSCAR',
    searchLoading: 'Cargando...',
    companyProfileApply: 'APLICAR',
    companyProfileDayAgo: 'día atrás',
    companyProfileDaysAgo: 'días atrás',
    companyProfileToday: 'hoy',
    industryFilterTitle: 'INDRUSTRIA',
    industryFilterSelectAll: 'Todos',
    industryFilterUnselectAll: 'Ninguno',
    'Full Time': 'Tiempo completo',
    'Part Time': 'Medio tiempo',
    All: 'Todos',
    cityFilterTitle: 'CIUDAD',
    cityFilterCityCenter: 'Ciudad Central',
    cityFilterCities: 'Ciudades',
    cityFilterRadius: 'Radio',
    milesFilterCities: 'Millas',
  },
  jobDescriptionQuickApply: {
    applyInstantly: 'APLICACIÓN RÁPIDA',
    redirectMessage:
      'Vas a ser redirigido al perfil de la compañía para ver más trabajos.',
    applicationSubitted: 'TU APLICACIÓN HA SIDO ENVIADA',
    uploadError:
      'Por favor adjunte un archivo en alguno de los formatos soportados. Formatos soportados son .pdf, .docx, and .doc.',
    alreadyApplied: 'YA HAS APLICADO',
    firstName: 'Nombres',
    lastName: 'Apellidos',
    email: 'Correo Electrónico',
    phone: 'Número de teléfono (opcional)',
    phone2: 'Número de teléfono',
    attachResume: 'Adjuntar hoja de vida',
    optional: '(opcional)',
    required: '(requerido)',
    sumbit: 'ENVIAR',
    quickApply: '1-APLICACIÓN RÁPIDA',
    quickApplyInfoText:
      'si esta no es tu información o quieres aplicar nuevamente, por favor',
    quickApplyInfoText2:
      'si esta no es tu información o quieres aplicar nuevamente, por favor haz clic abajo.',
    quickApplyClick: 'clic aquí.',
    resumeValidation:
      '¡La hoja de vida es requerida para aplicar a este trabajo!',
    applyNow: 'APLICAR AHORA',
    applyAgain: 'APLICAR DE NUEVO',
    loading: 'Cargando...',
  },
  shareModal: {
    copyLink: 'Copiar Enlace',
    copied: '¡Copiado al portapapeles!',
  },
  registerModal: {
    emailValidation1: 'Este correo electrónico ya ha sido usado.',
    emailValidation2: 'Por favor ingrese un correo electrónico válido',
    apply: 'APLICAR',
    email: 'Correo Electrónico',
    password: 'Contraseña',
    passwordText1: 'La contraseña debe ser  ',
    passwordText2: 'mínimo de 8 caracteres ',
    passwordText3: 'y contener al menos un  ',
    passwordText4: 'número, ',
    passwordText5: 'minúsculas, ',
    passwordText6: 'y ',
    passwordText7: 'mayúsculas',
    passwordText8: '',
    continueBtn: 'CONTINUAR',
    login: 'LOGIN',
  },
  loginModal: {
    incorrectLogin: 'El correo electrónico o la contraseña son incorrectos.',
    emailValidation: 'Por favor ingrese un correo electrónico válido',
    email: 'Correo Electrónico',
    password: 'Contraseña',
    forgotPassword: '¿Olvidó su contraseña?',
    apply: 'APLICAR',
    signOutConfirmation: '¿ESTÁ SEGURO QUE QUIERE CERRAR SESIÓN?',
    signOutOk: 'CONFIRMAR',
    signOutCancel: 'CANCELAR',
  },
  navigation: {
    openPositions: 'PUESTOS VACANTES',
    companyDescription: 'DESCRIPCIÓN DE LA COMPAÑÍA',
    jobDescription: 'DESCRIPCIÓN DEL TRABAJO',
    location: 'UBICACIÓN',
    skills: 'HABILIDADES',
    videos: 'VIDEOS',
    photos: 'FOTOS',
    apply: 'APPLICAR',
    jobsAt: 'Trabajos en',
  },
  resetNewPassword: {
    passwordResetLinkSent: 'SE ENVIÓ ENLACE PARA RESTABLECER CONTRASEÑA A: ',
    enterEmailAddress: 'Por favor ingrese su dirección de correo electrónico.',
    sendResetLink: 'Enviar enlace para restablecer contraseña',
    backToLogin: 'Volver a Login',
    passwordReseted:
      'SU CONTRASEÑA HA SIDO CAMBIADA, POR FAVOR VAYA A LA PÁGINA ANTERIOR O DE CLIC NUEVAMENTE EN EL LINK DE SU CORREO ELECTRÓNICO PARA COMPLEYAR EL PROCESO DE APLICACIÓN.',
    passwordErrorMessage:
      'No puede usar el enlace para restablecer contraseña más de una vez.',
    addNewPassword: 'ESCRIBA LA NUEVA CONTRASEÑA',
    resetPassword: 'CAMBIAR CONTRASEÑA',
  },
  getVerified: {
    lpnTitleText: 'ESPANOL GET AHEAD, GET VERIFIED',
    rnTitleText: 'ESPANOL GET AHEAD, GET VERIFIED',
    cnaTitleText: 'ESPANOL GET AHEAD, GET VERIFIED',
    svApiTitleText: 'ESPANOL Get Ahead, Get Verified',
    lpnSubtitleText: 'ESPANOL LPN LICENSE VERIFICATION',
    rnSubtitleText: 'ESPANOL RN LICENSE VERIFICATION',
    cnaSubtitleText: 'ESPANOL CNA CERTIFICATE VERIFICATION',
    svApiSubtitleText: '{{verificationType}} CERTIFICATE VERIFICATION',
    lpnDescriptionText:
      'ESPANOL Get ahead of the game and enhance your application by instantly verifying your license with the state board of New Jersey.',
    rnDescriptionText:
      'ESPANOL Get ahead of the game and enhance your application by instantly verifying your license with the state board of New Jersey.',
    cnaDescriptionText:
      'ESPANOL Get ahead of the game and enhance your application by instantly verifying your license with the state board of New Jersey.',

    svApiDescriptionText: `If you’re a candidate or employee, submit your credentials to be checked against the state board of {{state}}.
    
    Employers, upload and review verification details to ensure the proper certificate has been found.`,
    lpnFooterTitle: 'ESPANOL This is optional.',
    rnFooterTitle: 'ESPANOL This is optional.',
    cnaFooterTitle: 'ESPANOL This is optional.',
    lpnFooterText:
      'ESPANOL You can verify your credentials with Nursing Home Facility later if your application advances to next steps.',
    rnFooterText:
      'ESPANOL You can verify your credentials with Nursing Home Facility later if your application advances to next steps.',
    cnaFooterText:
      'ESPANOL You can verify your credentials with Nursing Home Facility later if your application advances to next steps.',
    svApiFooterText: 'Candidates, this is optional. You can verify your credentials with Nursing Home Facility later if needed.',
    buttonText: 'ES GET STARTED',
  },
  verification: {
    backToEmployee: 'REGRESAR AL PERFIL DEL EMPLEADO',
    employeeExtraText: 'y el detalle de la licencia del empleado sera actualizada.',
    applicantExtraText: 'ES y seguramente vinculado a tu aplicación.',
  },
  verificationStatus: {
    successTitleText: 'ES CONGRATS!',
    expiredTitleText: 'ES EXPIRED',
    notActiveTitleText: 'ES NOT ACTIVE',
    lpnSuccessSubtitleText: 'ES LPN LICENSE VERIFICATION COMPLETE',
    lpnExpiredSubtitleText: 'ES LPN LICENSE VERIFICATION COMPLETE',
    lpnNotActiveSubtitleText: 'ES LPN LICENSE VERIFICATION COMPLETE',
    rnSuccessSubtitleText: 'ES RN LICENSE VERIFICATION COMPLETE',
    rnExpiredSubtitleText: 'ES RN LICENSE VERIFICATION COMPLETE',
    rnNotActiveSubtitleText: 'ES RN LICENSE VERIFICATION COMPLETE',
    cnaSuccessSubtitleText: 'ES CNA CERTIFICATE VERIFICATION COMPLETE',
    cnaExpiredSubtitleText: 'ES CNA CERTIFICATE VERIFICATION COMPLETE',
    cnaNotActiveSubtitleText: 'ES CNA CERTIFICATE VERIFICATION COMPLETE',
    applicationExtraText: 'and securely linked to your application.',
    lpnSuccessDescriptionText:
      'ES Your license has been verified by the {{state}} State Board',
    lpnExpiredDescriptionText:
      'ES Your license has been verified by the {{state}} State Board, however it has expired. Please renew your license as soon as possible.',
    lpnNotActiveDescriptionText:
      'ES Your license has been verified by the {{state}} State Board and it is not active.',
    rnSuccessDescriptionText:
      'ES Your license has been verified by the {{state}} State Board',
    rnExpiredDescriptionText:
      'ES Your license has been verified by the {{state}} State Board, however it has expired. Please renew your license as soon as possible.',
    rnNotActiveDescriptionText:
      'ES Your license has been verified by the {{state}} State Board and it is not active.',
    cnaSuccessDescriptionText:
      'ES Your certificate has been verified by the {{state}} State Board',
    cnaExpiredDescriptionText:
      'ES Your certificate has been verified by the {{state}} State Board, however it has expired. Please renew your certificate as soon as possible.',
    cnaNotActiveDescriptionText:
      'ES Your certificate has been verified by the {{state}} State Board and it is not active.',
    subDescriptionText: 'ES We’ll be in touch soon regarding next steps.',
    boldText: 'ES Best of luck!',
    buttonText: 'ES SEE COMPANY PROFILE',
  },
  verificationError: {
    lpnTitleText: 'ES LPN LICENSE VERIFICATION',
    lpnSubtitleText: 'ES Something went wrong...',
    lpnDescriptionText:
      'ES We’re sorry, but we were unable to verify your license with the details provided.',
    lpnDescriptionTextError503:
      'ES Unfortunately we were unable to establish a connection to Nursys to verify your license. We will try again on your behalf in 1 hour.',
    lpnButtonText: 'ES TRY AGAIN',
    rnTitleText: 'ES RN LICENSE VERIFICATION',
    rnSubtitleText: 'ES Something went wrong...',
    rnDescriptionText:
      'ES We’re sorry, but we were unable to verify your license with the details provided.',
    rnDescriptionTextError503:
      'ES Unfortunately we were unable to establish a connection to Nursys to verify your license. We will try again on your behalf in 1 hour.',
    rnButtonText: 'ES TRY AGAIN',
    cnaTitleText: 'ES CNA CERTIFICATE VERIFICATION',
    cnaSubtitleText: 'ES Something went wrong...',
    cnaDescriptionText:
      'ES We’re sorry, but we were unable to verify your license with the details provided.',
    cnaDescriptionTextError503:
      'ES Unfortunately we were unable to establish a connection to Nursys to verify your certificate. We will try again on your behalf in 1 hour.',
    cnaButtonText: 'ES TRY AGAIN',
  },
  verificationSuccess: {
    lpnTitleText: 'ES LPN LICENSE VERIFICATION COMPLETE',
    lpnDescriptionText:
      'ES You have already verified your license with the {{state}} State Board.',
    lpnButtonText: 'ES SEE COMPANY PROFILE',

    rnTitleText: 'ES RN LICENSE VERIFICATION COMPLETE',
    rnDescriptionText:
      'ES You have already verified your license with the {{state}} State Board.',
    rnButtonText: 'ES SEE COMPANY PROFILE',

    cnaTitleText: 'ES CNA CERTIFICATE VERIFICATION COMPLETE',
    cnaDescriptionText:
      'ES You have already verified your certificate with the {{state}} State Board.',
    cnaButtonText: 'ES SEE COMPANY PROFILE',
  },
  lpnVerification: {
    lpnTitleText: 'ES LPN LICENSE VERIFICATION',
    rnTitleText: 'ES RN LICENSE VERIFICATION',
    subtitleText:
      'ES Please enter your information below to successfully process your verification for the state of New Jersey.',
    licenseNumber: 'ES License Number',
    licenseNumberPopup: 'ES Contains 6 characters or more e.g. GA12F5',
    licenseNumberTextWarning: 'ES Please enter your license number',
    ssnNumber: 'ES Last 4 Digits of SSN',
    ssnNumberTextWarning: 'ES Please enter the last 4 digits of your SSN',
    birthYear: 'ES What year were you born',
    birthYearTextPlaceholder: 'ES Select Year',
    birthYearTextWarning: 'ES Please select your year of birth',
    dragTitleText: 'ES Upload your license',
    dragTitleOptionalText: 'ES (optional)',
    dragSubTitleText: 'ES Drag and drop your file here or click to upload',
    dragFileType: 'ES Accepted files: "pdf,docx,doc,jpg,jpeg,png"',
    dragFileSize: 'ES Max. size: 10MB',
    submitButtonText: 'ES SUBMIT',
    footerText:
      'ES By providing these credentialing details, you are agreeing to our',
    termsAndConditions: 'ES Terms and Conditions.',
  },
  svVerification: {
    typeTitleText: '{{verificationType}} CERTIFICATE VERIFICATION',
    subtitleText:
      'Enter information as appears on your {{state}} Certificate.',
    certificateNumber: 'Certificate Number',
    certificateNumberPlaceholder: 'Enter Certificate Number',
    certificateNumberWarning: 'Please enter your Certificate Number',
    lastFourSsn: 'Last 4 Digits of SSN',
    lastFourSsnPlaceholder: '****',
    lastFourSsnWarning: 'Please enter the last four digits of SSN',
    firstName: 'First Name',
    firstNamePlaceholder: 'Enter First Name',
    firstNameWarning: 'Please enter your First Name',
    lastName: 'Last Name',
    lastNamePlaceholder: 'Enter Last Name',
    lastNameWarning: 'Please enter your Last Name',
    dragTitleText: 'Upload your license',
    dragTitleOptionalText: '(optional)',
    dragSubTitleText: 'Drop your file here or click to upload',
    dragFileType: 'Accepted files: "pdf,docx,doc,jpg,jpeg,png"',
    dragFileSize: 'Max. size: 10MB',
    submitButtonText: 'Verify Credential',
    footerText:
      'By providing these credentialing details, you are agreeing to our',
    termsAndConditions: 'Terms and Conditions.',
  },
  svVerificationSuccess: {
    titleText: 'The credentials have been submitted for review.',
    descriptionText: 'If you’re an employer, you will also need to review the results on the employee or candidate page. \n\nOtherwise, you’re good to go!',
  },
  svVerificationError: {
    errorTitleText: 'Something went wrong...',
    descriptionText: '{{verificationType}} Certificate Verification failed',
    errorFooterText: 'Sorry. We were unable to verify the {{verificationType}} certificate.',
    notFoundTitleText: 'No Results',
    notFoundFooterText: 'We were unable to verify the CNA certificate with the details provided.',
    buttonText: 'Try Again',
  },
}

export default es

const jobApplicationDefaultState = {
  /* We use this property to signal when to start autosave interval and when to show form component */
  startFullApplyFormActivate: false,
  /* If fullApplyFormQuestionsAutosave is true we will save form
  (only truman questions) automatically every 30 second
  and set fullApplyFormQuestionsAutosave to false.
  We changed it to true again if any of the input values is changed */
  fullApplyFormQuestionsAutosave: false,
  /* If fullApplyFormPersonalQuestionsAutosave is true we will save form
  (only Personal Questions, Education and Workhistory) automatically every 30 second
  and set fullApplyFormPersonalQuestionsAutosave to false.
  We changed it to true again if any of the input values is changed. */
  fullApplyFormPersonalQuestionsAutosave: false,
  answers: null, // Object
  /* Personal Question Types */
  submitRequirements: {
    firstTimeSubmitButtonClicked: false,
    personalQuestionsRequirements: {
      resumeUpload: {
        submitReady: false,
      },
      personQuestion: {
        submitReady: false,
      },
      educationQuestion: {
        submitReady: false,
      },
      experienceQuestion: {
        submitReady: false,
      },
    },
    answersRequirements: {},
  },
  /* -------------------------------- */
  fullApplicationPersonalQuestions: {
    /*  ----------------
    PersonalInformationQuestion
    --------------- */
    personQuestion: {
      first_name: null,
      last_name: null,
      address: null,
      city: null,
      state: null, // ??// in Churchill it's always 'TX' There is no input to change it.
      zip_code: null,
      country: null, // just a two letter shorthand saved in churchill,
      email: null, // ??// not able to change it in churchill and not while saving that data to api
      mobile_phone_number: null,
    },
    /*  ----------------
    EducationQuestion
    ---------------- */
    educationQuestion: [
      {
        school: null,
        major: null,
        qualification: null,
        dates_opt_out: false,
        start_date: null,
        end_date: null,
      },
    ],
    /*  ----------------
    ExperienceQuestion
    ---------------- */
    experienceQuestion: [
      {
        employer: null,
        title: null,
        description: null,
        start_date: null,
        end_date: null,
        current: false,
      },
    ],
  },
  questionExpand: {
    /* We only put experienceQuestion and educationQuestion in array because other question types are in array also so it's
    easier to handle it, but since we can not have more then one of those it's not really needed  */
    educationQuestion: [{ expand: false, id: '1-education' }],
    experienceQuestion: [{ expand: false, id: '1-experience' }],
    textQuestion: [],
    videoQuestion: [],
    sliderQuestion: [],
    calendarQuestion: [],
    timeAvailabilityQuestion: [],
    multipleChoiceQuestion: [],
    signatureQuestion: [],
  },
  /* Original response that we get back from API */
  fullApplicationForm: null,
  /* Here we save external link to the resume file that was uploaded */
  uploadedResumeFile: null,
}

/* ---- DEFAULT STATE OF PROPERTIES ---- */
const educationQuestion = {
  school: null,
  major: null,
  qualification: null,
  dates_opt_out: false,
  start_date: null,
  end_date: null,
}

const experienceQuestion = {
  employer: null,
  title: null,
  description: null,
  start_date: null,
  end_date: null,
  current: false,
}

/* ---- END DEFAULT STATE OF PROPERTIES ----- */

const jobApplicationReducer = (state = jobApplicationDefaultState, action) => {
  switch (action.type) {
    /* Personal Questions, Education and Workhistory questions - we update one property at a time one change */
    case 'SAVE_PERSONAL_INFORMATION_QUESTIONS': {
      const newPersonalInformationState = JSON.parse(JSON.stringify(state))
      /* since personQuestion is only an object we don't need 'index' so we send 'null' instead. In educationQuestion and
      experienceQuestion we have array of objects so we need index to know which one to update */
      if (action.payload.index === null) {
        newPersonalInformationState.fullApplicationPersonalQuestions[
          action.payload.questionType
        ][action.payload.property] = action.payload.value
      } else {
        newPersonalInformationState.fullApplicationPersonalQuestions[
          action.payload.questionType
        ][action.payload.index][action.payload.property] = action.payload.value
      }

      /* Every time something in the Personal questions is updated we enable fullApplyFormPersonalQuestionsAutosave
      then disable it after autosave.
      */
      newPersonalInformationState.fullApplyFormPersonalQuestionsAutosave = true

      return { ...state, ...newPersonalInformationState }
    }

    /* Here we save all Personal information questions at once when when we upload resume
    for automatic input fulfillment. We only save all at once when we upload a resume since we wanna save
    it to redux only after we get response back from API that it's saved to database. */
    case 'SAVE_FULL_APPLY_FORM_PERSONAL_DATA': {
      const newPersonalInformationResumeState = JSON.parse(
        JSON.stringify(state)
      )
      const personObject = { ...action.payload.personalData }
      /* We remove education and workhistory from person object since we wanna save them separately */
      delete personObject.education
      delete personObject.workhistory

      newPersonalInformationResumeState.fullApplicationPersonalQuestions.personQuestion = personObject
      newPersonalInformationResumeState.fullApplicationPersonalQuestions.educationQuestion =
        action.payload.personalData.education
      newPersonalInformationResumeState.fullApplicationPersonalQuestions.experienceQuestion =
        action.payload.personalData.workhistory

      return { ...state, ...newPersonalInformationResumeState }
    }

    case 'SAVE_TRUMAN_QUESTIONS_INFORMATION': {
      const newTrumanQuestionsInformationState = JSON.parse(
        JSON.stringify(state)
      )
      /* Every time something in the question is updated we enable fullApplyFormQuestionsAutosave
      then disable it after autosave.
      */

      newTrumanQuestionsInformationState.fullApplyFormQuestionsAutosave = true

      return {
        ...newTrumanQuestionsInformationState,
        answers: {
          ...newTrumanQuestionsInformationState.answers,
          [action.payload.question_id]: {
            ...newTrumanQuestionsInformationState.answers[
              action.payload.question_id
            ],
            ...action.payload.propertyValue,
          },
        },
      }
    }
    case 'ADD_NEW_QUESTION':
      return {
        ...state,
        fullApplicationPersonalQuestions: {
          ...state.fullApplicationPersonalQuestions,
          [action.payload.property]: [
            ...state.fullApplicationPersonalQuestions[action.payload.property],
            eval(action.payload.property), // turn string into variable
          ],
        },
      }
    case 'DELETE_QUESTION': {
      const newDeleteQuestionState = JSON.parse(JSON.stringify(state))
      newDeleteQuestionState.fullApplicationPersonalQuestions[
        action.payload.property
      ].splice(action.payload.index, 1)

      newDeleteQuestionState.fullApplyFormPersonalQuestionsAutosave = true
      return {
        ...state,
        ...newDeleteQuestionState,
      }
    }

    case 'CHANGE_QUESTION_EXPAND': {
      const newQuestionExpandState = JSON.parse(
        JSON.stringify(state.questionExpand)
      )
      /* Expand all the questions accordion */
      if (action.payload.property === 'allQuestionsExpand') {
        Object.keys(newQuestionExpandState).forEach((key) => {
          newQuestionExpandState[key].forEach((question) => {
            question.expand = true
          })
        })
        /* Collapse all the questions accordion */
      } else if (action.payload.property === 'allQuestionsCollapse') {
        Object.keys(newQuestionExpandState).forEach((key) => {
          newQuestionExpandState[key].forEach((question) => {
            question.expand = false
          })
        })
        /* Expand/Collapse individual question accordion */
      } else {
        const index = newQuestionExpandState[
          action.payload.property.questionType
        ].findIndex((property) => property.id === action.payload.property.id)
        newQuestionExpandState[action.payload.property.questionType][
          index
        ].expand = !newQuestionExpandState[
          action.payload.property.questionType
        ][index].expand
      }

      return {
        ...state,
        questionExpand: { ...state.questionExpand, ...newQuestionExpandState },
      }
    }
    case 'SAVE_FULL_APPLY_FORM': {
      const newFormState = JSON.parse(JSON.stringify(state))
      newFormState.fullApplicationForm = action.payload.form
      return { ...state, ...newFormState }
    }

    case 'SAVE_PERSONAL_QUESTIONS_SUBMIT_REQUIREMENTS': {
      const newPersonalQuestionsSubmitRequirementsState = JSON.parse(
        JSON.stringify(state)
      )

      newPersonalQuestionsSubmitRequirementsState.submitRequirements.personalQuestionsRequirements[
        action.payload.questionType
      ].submitReady = action.payload.submitReady
      return { ...state, ...newPersonalQuestionsSubmitRequirementsState }
    }

    case 'SAVE_SUBMIT_REQUIREMENTS': {
      const newSubmitRequirementsState = JSON.parse(JSON.stringify(state))

      newSubmitRequirementsState.submitRequirements.answersRequirements[
        action.payload.id
      ].submitReady = action.payload.submitReady
      return { ...state, ...newSubmitRequirementsState }
    }

    case 'SAVE_SUBMIT_BUTTON_CLICKED': {
      const newSubmitRequirementsButtonState = JSON.parse(JSON.stringify(state))

      newSubmitRequirementsButtonState.submitRequirements.firstTimeSubmitButtonClicked =
        action.payload.state
      return { ...state, ...newSubmitRequirementsButtonState }
    }

    case 'START_FULL_APPLY_FORM_ACTIVATE': {
      const newStartFullApplyFormActivateState = JSON.parse(
        JSON.stringify(state)
      )

      newStartFullApplyFormActivateState.startFullApplyFormActivate =
        action.payload.activateState
      return { ...state, ...newStartFullApplyFormActivateState }
    }

    case 'DISABLE_FULL_APPLY_FORM_AUTOSAVE': {
      const newFullApplyFormAutosaveState = JSON.parse(JSON.stringify(state))

      if (
        action.payload.disabledAutosaveProperties.includes(
          'fullApplyFormQuestionsAutosave'
        )
      ) {
        newFullApplyFormAutosaveState.fullApplyFormQuestionsAutosave = false
      }

      if (
        action.payload.disabledAutosaveProperties.includes(
          'fullApplyFormPersonalQuestionsAutosave'
        )
      ) {
        newFullApplyFormAutosaveState.fullApplyFormPersonalQuestionsAutosave = false
      }

      return { ...state, ...newFullApplyFormAutosaveState }
    }

    case 'SAVE_SPECIFIC_ANSWERS': {
      const newSpecificAnswersFormState = JSON.parse(JSON.stringify(state))
      /*  We use this to save specific questions from response on /answers endpoint. */

      /* Signature - when we save signature for the first time we send base64 and then in response we get back 'external link'.
      We don't update this external link until we reload the page and call again '/savedapplications/{id}' endpoint to fetch all
      the answers. If user makes the Signature and continues to fill out the rest of form without reloading the page we will be
      sending base64 Signature with every call to '/answers' endpoint which is big. That is why we wanna update it as soon as we get external link for the first time. */

      const arrayOfAnswerId = Object.keys(
        action.payload.questionAnswers.answers
      )
      arrayOfAnswerId.map((answerId) => {
        if (
          action.payload.questionAnswers.answers[answerId].answer_type ===
          'Signature'
        ) {
          newSpecificAnswersFormState.answers[answerId] =
            action.payload.questionAnswers.answers[answerId]
        }
      })

      return { ...state, ...newSpecificAnswersFormState }
    }

    case 'SAVE_UPLOADED_RESUME_FILE': {
      const newUploadedResumeFile = JSON.parse(JSON.stringify(state))

      /* We get back empty object if there is no resume. In this case we dont save nothing and just leave 'uploadedResumeFile' property as 'null' */
      if (action.payload.uploadedResumeFile.resume_link) {
        newUploadedResumeFile.uploadedResumeFile =
          action.payload.uploadedResumeFile
      }

      return { ...state, ...newUploadedResumeFile }
    }

    /* Since data that we get back from API is not organised how we need to we use this API
    to do that */
    case 'ADJUST_FULL_APPLY_FORM_RESPONSE': {
      const newAdjustFormState = JSON.parse(JSON.stringify(state))

      /* RESET VALUES */
      newAdjustFormState.answers = {} // we put empty object instead of null because if there is no job we also get "{ }" in response but since "{ }" is not the same as "null" we know that we tried to fetch the jobs but there aren't any
      newAdjustFormState.submitRequirements = {
        firstTimeSubmitButtonClicked: false,
        personalQuestionsRequirements: {
          resumeUpload: {
            submitReady: false,
          },
          personQuestion: {
            submitReady: false,
          },
          educationQuestion: {
            submitReady: false,
          },
          experienceQuestion: {
            submitReady: false,
          },
        },
        answersRequirements: {},
      }
      newAdjustFormState.questionExpand = {
        educationQuestion: [{ expand: false, id: '1-education' }],
        experienceQuestion: [{ expand: false, id: '1-experience' }],
        textQuestion: [],
        videoQuestion: [],
        sliderQuestion: [],
        calendarQuestion: [],
        timeAvailabilityQuestion: [],
        multipleChoiceQuestion: [],
        signatureQuestion: [],
      }

      /* SAVE PERSONAL QUESTIONS */
      /* ------------------------- */
      if (action.payload.form.person) {
        const personObject = { ...action.payload.form.person }
        /* We remove education and workhistory from person object since we wanna save them separately */
        delete personObject.education
        delete personObject.workhistory

        newAdjustFormState.fullApplicationPersonalQuestions.personQuestion = personObject
      }
      if (
        action.payload.form.person &&
        action.payload.form.person.education &&
        action.payload.form.person.education.length > 0
      ) {
        newAdjustFormState.fullApplicationPersonalQuestions.educationQuestion =
          action.payload.form.person.education
      }
      if (
        action.payload.form.person &&
        action.payload.form.person.workhistory &&
        action.payload.form.person.workhistory.length > 0
      ) {
        newAdjustFormState.fullApplicationPersonalQuestions.experienceQuestion =
          action.payload.form.person.workhistory
      }

      /* SAVE QUESTIONS */
      /* ------------------------- */
      action.payload.form.application_schema.pages[0].questions.map(
        (question) => {
          /* Text Question */
          /* ---------------------------------- */
          if (question.answer.answer_type === 'Text') {
            /* We adjust the expand state */
            newAdjustFormState.questionExpand.textQuestion.push({
              id: question.id,
              expand: false,
            })
            /* We adjust answers. Based on hom many questions we have that many empty answers data we add to array for every questions */
            newAdjustFormState.answers[question.id] = {
              answer_type: 'Text',
              question_id: question.id,
              text: null,
            }

            /* We make new property with submitReady (if specific question is ready to be submited), optional (if question is optional or required) */
            newAdjustFormState.submitRequirements.answersRequirements[
              question.id
            ] = {
              answer_type: 'Text',
              question_id: question.id,
              optional: question.optional,
              submitReady: false,
            }

            /* If we answerd some of the questions we fill in the data */
            if (action.payload.form.answers[question.id]) {
              newAdjustFormState.answers[question.id] = {
                ...action.payload.form.answers[question.id],
              }
            }
          }

          /* VIDEO QUESTION */
          /* ---------------------------------- */
          if (question.answer.answer_type === 'Video') {
            newAdjustFormState.questionExpand.videoQuestion.push({
              id: question.id,
              expand: false,
            })

            newAdjustFormState.answers[question.id] = {
              answer_type: 'Video',
              question_id: question.id,
              uploaded_file: {
                external_link: null,
                mime_type: null,
                // id: null,
                // date_created: '',    // used in truman but not needed
                // name: '',
                // notes: '',
                // uuid: '',
              },
            }

            newAdjustFormState.submitRequirements.answersRequirements[
              question.id
            ] = {
              answer_type: 'Video',
              question_id: question.id,
              optional: question.optional,
              submitReady: false,
            }

            if (action.payload.form.answers[question.id]) {
              newAdjustFormState.answers[question.id] = {
                ...action.payload.form.answers[question.id],
              }
            }
          }

          /* SLIDER QUESTION */
          /* ---------------------------------- */
          if (question.answer.answer_type === 'Slider') {
            newAdjustFormState.questionExpand.sliderQuestion.push({
              id: question.id,
              expand: false,
            })

            newAdjustFormState.answers[question.id] = {
              answer_type: 'Slider',
              question_id: question.id,
              slider_value: null,
            }

            newAdjustFormState.submitRequirements.answersRequirements[
              question.id
            ] = {
              answer_type: 'Slider',
              question_id: question.id,
              optional: question.optional,
              submitReady: false,
            }
          }

          if (action.payload.form.answers[question.id]) {
            newAdjustFormState.answers[question.id] = {
              ...action.payload.form.answers[question.id],
            }
          }

          /* CALENDAR QUESTION */
          /* ---------------------------------- */
          if (question.answer.answer_type === 'Calendar') {
            newAdjustFormState.questionExpand.calendarQuestion.push({
              id: question.id,
              expand: false,
            })

            newAdjustFormState.answers[question.id] = {
              answer_type: 'Calendar',
              question_id: question.id,
              date: null,
            }

            newAdjustFormState.submitRequirements.answersRequirements[
              question.id
            ] = {
              answer_type: 'Calendar',
              question_id: question.id,
              optional: question.optional,
              submitReady: false,
            }

            if (action.payload.form.answers[question.id]) {
              newAdjustFormState.answers[question.id] = {
                ...action.payload.form.answers[question.id],
              }
            }
          }

          /* TIMEAVAILABILITY QUESTION */
          /* ---------------------------------- */
          if (question.answer.answer_type === 'TimeAvailability') {
            newAdjustFormState.questionExpand.timeAvailabilityQuestion.push({
              id: question.id,
              expand: false,
            })

            newAdjustFormState.answers[question.id] = {
              answer_type: 'TimeAvailability',
              question_id: question.id,
              time_availability: [],
            }

            newAdjustFormState.submitRequirements.answersRequirements[
              question.id
            ] = {
              answer_type: 'TimeAvailability',
              question_id: question.id,
              optional: question.optional,
              submitReady: false,
            }

            if (action.payload.form.answers[question.id]) {
              newAdjustFormState.answers[question.id] = {
                ...action.payload.form.answers[question.id],
              }
            }
          }

          /* MULTIPLECHOICE QUESTION */
          /* ---------------------------------- */
          if (question.answer.answer_type === 'MultipleChoice') {
            newAdjustFormState.questionExpand.multipleChoiceQuestion.push({
              id: question.id,
              expand: false,
            })

            newAdjustFormState.answers[question.id] = {
              answer_type: 'MultipleChoice',
              question_id: question.id,
              choices: [],
            }

            newAdjustFormState.submitRequirements.answersRequirements[
              question.id
            ] = {
              answer_type: 'MultipleChoice',
              question_id: question.id,
              optional: question.optional,
              submitReady: false,
            }

            if (action.payload.form.answers[question.id]) {
              newAdjustFormState.answers[question.id] = {
                ...action.payload.form.answers[question.id],
              }
            }
          }

          /* SIGNATURE QUESTION */
          /* ---------------------------------- */
          if (question.answer.answer_type === 'Signature') {
            newAdjustFormState.questionExpand.signatureQuestion.push({
              id: question.id,
              expand: false,
            })

            newAdjustFormState.answers[question.id] = {
              answer_type: 'Signature',
              question_id: question.id,
              uploaded_file: {
                mime_type: null,
                data: null,
              },
            }

            newAdjustFormState.submitRequirements.answersRequirements[
              question.id
            ] = {
              answer_type: 'Signature',
              question_id: question.id,
              optional: question.optional,
              submitReady: false,
            }

            if (action.payload.form.answers[question.id]) {
              newAdjustFormState.answers[question.id] = {
                ...action.payload.form.answers[question.id],
              }
            }
          }
        }
      )
      return { ...state, ...newAdjustFormState }
    }
    default:
      return state
  }
}

export default jobApplicationReducer

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import VerificationFlow from 'VerificationFlow'
import { saveRouteData } from '../../../actions/global_actions'
import {
  getLicenseVerificationDataAction,
  getCredentialingStateFullNameAction,
} from '../../../actions/api_actions'
import spinner from '../../../../../public/assets/img/spinner.gif'

const Verification = (props) => {
  const [parsedQueryString, setParsedQueryString] = useState({})
  const [activeScreen, setActiveScreen] = useState({
    // "getVerified",
    // "licenseVerification"
    // "verificationStatus"
    // "verificationError"
    // "verificationSuccess"
    activeStep: null,
    // "success", "expired", "notActive"
    verificationStatus: null,
  })
  const [longStateName, setLongStateName] = useState('')
  const [lastFourSsnEnabled, setLastFourSsnEnabled] = useState(false)
  const [verificationEngine, setVerificationEngine] = useState('')

  useEffect(() => {
    const {
      route,
      match,
      location,
    } = props
    /* save global route data */
    props.saveRouteData(route, match, location)

    /* same parsed Query strings in state */
    setParsedQueryString(queryString.parse(location.search))
  }, [])

  useEffect(() => {
    if (parsedQueryString.person_id) {
      props
        .getLicenseVerificationDataAction(
          window.activeEnvironment,
          parsedQueryString.person_id,
          parsedQueryString.job_id,
        )
        .then((licenseResponse) => {
          /* In getLicenseVerificationDataAction API and URL we are only getting
          short version of state name. To get the long version we need to call this API */
          props
            .getCredentialingStateFullNameAction(
              window.activeEnvironment,
              parsedQueryString.state,
              // we need to use uppercase for type since API wont work otherwise
              props.match.params.slug.toUpperCase(),
            )
            .then((stateResponse) => {
              /* if user didn't go through verification process, there is no data so we get back empty
               object in response. Remember that empty object !!{} is "true" */
              setLongStateName(stateResponse.long_name)
              setLastFourSsnEnabled(stateResponse.last_4_ssn_collection_enabled)
              setVerificationEngine(stateResponse.engine)
              handleChangeScreenConditions(licenseResponse, stateResponse)
            })
            .catch(() => {
              console.error('ERROR GET LONG STATE NAME')
              /* If there is an error with fetching long state name set short name from URL */
              setLongStateName(parsedQueryString.state)
              setLastFourSsnEnabled(false)
              const { slug } = props?.match?.params;
              handleChangeScreenConditions(licenseResponse, { state: parsedQueryString.state, jobType: slug.toUpperCase() })
            })
        })
        .catch((error) => {
          handleChangeActiveStep('verificationError', null)
        })
    }
  }, [parsedQueryString])

  const handleChangeScreenConditions = (verificationData, stateResponse) => {
    let activeStep = null
    let verificationStatus = null
    
    const { job_type: verficationJobType, verificationState } = verificationData;
    const { job_type: stateJobType, state } = stateResponse;
    const isVerifiedByStateAndJobType = verficationJobType === stateJobType || verificationState === state;

    if (verificationData) {
      activeStep = 'getVerified'
      verificationStatus = null
    }

    /* If in URL we get "directVerification" prop "true" that means we are getting to this page from truman so we get directly to "licenseVerification" screen */
    if (verificationData && parsedQueryString.directVerification === 'true') {
      activeStep = 'licenseVerification'
      verificationStatus = null
    }

    if (isVerifiedByStateAndJobType && verificationData?.active && ['yes', 'valid'].includes(verificationData.active.toLowerCase())) {
      activeStep = 'verificationSuccess'
      verificationStatus = null
    }

    setActiveScreen({
      activeStep,
      verificationStatus,
    })
  }

  const handleChangeActiveStep = (activeStep, verificationStatus) => {
    /* When activeStep is "verificationStatus" we show VerificationStatus component but design and
    the message depend on "verificationStatus" that can be "success", "expired", "notActive".
    When we call this function we send activeStep property and if activeStep is "verificationStatus"
    we also send "verificationStatus" property.
    If it's "verificationError" we send error code.
    If it's some other step we send "null" for verificationStatus */

    setActiveScreen({
      activeStep,
      verificationStatus,
    })
  }

  return activeScreen.activeStep ? (
    <VerificationFlow
      verificationType={props.match.params.slug.toLowerCase()}
      verificationData={parsedQueryString}
      longStateName={longStateName}
      lastFourSsnEnabled={lastFourSsnEnabled}
      verificationEngine={verificationEngine}
      changeActiveStep={handleChangeActiveStep}
      activeStep={activeScreen.activeStep}
      verificationStatus={activeScreen.verificationStatus}
    />
  ) : (
    <img
      data-testid="spinner"
      src={spinner}
      width="50"
      style={{
        margin: '200px auto',
        display: 'flex',
      }}
    />
  )
}

Verification.serverFetch = []

const mapStateToProps = () => ({})

Verification.propTypes = {
  // comes from react-router-config. We are free to add any additional props
  // we would like to a route inside of 'router.js'.
  route: PropTypes.object,
  // route prop (from react-router). Represent where the app is now.
  location: PropTypes.object,
  // route prop (from react-router).
  // Contains information about how a <Route path> matched the URL. match objects.
  match: PropTypes.object,
  // save to redux global route data that we get from
  // react-router and react-router-config to use it across the app.
  saveRouteData: PropTypes.func,
  getLicenseVerificationDataAction: PropTypes.func,
  getCredentialingStateFullNameAction: PropTypes.func,
}

export default connect(mapStateToProps, {
  saveRouteData,
  getLicenseVerificationDataAction,
  getCredentialingStateFullNameAction,
})(Verification)
